import React from 'react'

const className =
  'font-serif text-h1lead md:text-h1leadmd antialiased leading-[42px] md:leading-[80px]'
export const H1ClassName = className

const H1Lead: React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >
> = ({ children, className, ...other }) => {
  return (
    <h1
      className={H1ClassName + (className ? ` ${className}` : '')}
      {...other}
    >
      {children}
    </h1>
  )
}

export default H1Lead
