import gql from 'graphql-tag'
import { ConsultancyService } from '~/fragments/ConsultancyServiceType'

export const ConsultancyProject = gql`
  ${ConsultancyService}
  fragment ConsultancyProject on ConsultancyProjectType {
    id
    absoluteUrl
    clientName
    description
    featuredOnHomepageImage
    featuredOnTradeImage
    image
    labels
    location
    name
    otherImages
    status
    yearCompleted
    captions
    service {
      ...ConsultancyService
    }
  }
`
